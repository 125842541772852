<template>
  <section>
    <!-- <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">{{ route.params.id ? 'Actualizar' : 'Crear' }} Bodega</p>
      </div>
    </div> -->
    <div class="grid grid-cols-4 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">Código </span>
        <InputText class="rounded w-full border uppercase" :disabled="route.params.id" type="text" v-model="model.WhsCode" placeholder="BOD12345" />
        <MessageError :text="errors.WhsCode"/>
      </div>
      <div class="flex flex-col col-span-2">
        <span class="font-bold">Nombre Bod </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.WhsName" />
        <MessageError :text="errors.WhsName"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Costo de ventas </span>
        <InputText class="rounded w-full border" type="text" v-model="model.SaleCostAc" />
        <!-- <MessageError :text="errors.SaleCostAc"/> -->
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Tranferencias</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.TransferAc" placeholder="" />
        <!-- <textarea v-model="model.UserText" class="rounded w-full border"></textarea> -->
        <!-- <MessageError :text="errors.TransferAc"/> -->
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Centro de costos </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_CentroCosto" />
        <!-- <MessageError :text="errors.U_PHR_CentroCosto"/> -->
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Dias promedio</span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_DaysAverage"/>
        <!-- <MessageError :text="errors.U_PHR_DaysAverage"/> -->
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Dias min </span>
        <InputText class="rounded w-full border" type="number" v-model="model.U_PHR_DaysMin" />
        <!-- <MessageError :text="errors.U_PHR_DaysMinv"/> -->
      </div>
      <div class="flex flex-col mt-auto">
        <span class="font-bold">Dias Max </span>
        <InputText class="rounded w-full border uppercase" type="number" v-model="model.U_PHR_DaysMax" />
        <!-- <MessageError :text="errors.U_PHR_DaysMax"/> -->
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Punto Reorden </span>
        <InputText class="rounded w-full border " type="text" v-model="model.U_PHR_PuntoReorden" />
        <!-- <MessageError :text="errors.U_PHR_PuntoReorden"/> -->
      </div>
      <div class="flex flex-col mt-auto">
        <span class="font-bold">Cod Dpto </span>
        <!-- <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_Dpto" /> -->
        <!-- <Dropdown v-model="model.U_PHR_Dpto" :options="dptos" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un estado" class="rounded"/> -->
        <AutoComplete v-model="model.U_PHR_Dpto" optionLabel="label" optionValue="id" forceSelection  dropdown :suggestions="dptos" @complete="searchDpto" />
        <MessageError :text="errors.U_PHR_Dpto"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Dirección </span>
        <InputText class="rounded w-full border" type="text" v-model="model.StreetNo" />
        <!-- <MessageError :text="errors.StreetNo"/> -->
      </div>
      <!-- <div class="flex flex-col">
        <span class="font-bold">Bbs Entry</span>
        <InputText class="rounded w-full border" type="date" v-model="model.AbsEntry"/>
        <MessageError :text="errors.AbsEntry"/>
      </div> -->
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import BodegasService from '../../../../../services/bodegas.service'

// instancias
const route = useRoute()
const _BodegasService = ref(new BodegasService())
const dptos = ref(null)
const emit = defineEmits(['customChange'])
// referencias

const validationSchema = yup.object({
  WhsCode: yup.string().required('El código es requerido').label(''),
  WhsName: yup.string().required('La descripción es requerida').label(''),
  // SaleCostAc: yup.string().required('El CUM es requerido').label(''),
  // TransferAc: yup.string().max(5).required('El Consecutivo es requerido').label(''),
  U_PHR_Dpto: yup.string().required('la el dpto es requerida').label('')
  // StreetNo: yup.date().required('La fecha de vencimiento es requerida').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('WhsCode', null, { initialValue: '' })
  useField('WhsName', null, { initialValue: '' })
  useField('SaleCostAc', null, { initialValue: '' })
  useField('TransferAc', null, { initialValue: '' })
  useField('U_PHR_CentroCosto', null, { initialValue: '' })
  useField('U_PHR_DaysAverage', null, { initialValue: '' })
  useField('U_PHR_PuntoReorden', null, { initialValue: '' })
  useField('U_PHR_Dpto', null, { initialValue: '' })
  useField('StreetNo', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string' && index !== 'PicturName') {
        model[index] = item.toUpperCase()
      }
    }
    if (route.params.id) {
      _BodegasService.value.editarBodega(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Bodega Actualizada',
          text: 'La Bodega se ha actualizado correctamente'
        })
      })
    } else {
      _BodegasService.value.crearBodega(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Bodega creada',
          text: 'La Bodega se ha creado correctamente'
        })
      })
    }
    emit('crearBodega')
})
const resetFilters = () => {
  model.value = {
    WhsCode: '',
    WhsName: '',
    SaleCostAc: '',
    TransferAc: '',
    U_PHR_CentroCosto: '',
    U_PHR_DaysAverage: '',
    U_PHR_DaysMin: '',
    U_PHR_DaysMax: '',
    U_PHR_PuntoReorden: '',
    U_PHR_Dpto: '',
    StreetNo: ''
  }
}

const searchDpto = (event) => {
  if (event.query) {
    _BodegasService.value.getDptos(event.query).then(({ data }) => {
        // dptos.value = data.map(m => m.nomMpio)
        dptos.value = data
    })
  } else {
    // dptos.value = dptos.value
  }
}

onMounted(() => {
  if (route.params.id) {
    _BodegasService.value.listarBodegas(route.params.id).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item.map(m => m.nomMpio + ' ' + m.nomDpto)
      }
    })
  }
  _BodegasService.value.getDptos().then(({ data }) => {
    dptos.value = data
  })
})
</script>
<style>
</style>
